import React, { useState,useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import {Row,Col} from 'react-bootstrap';
import { useCookies } from "react-cookie";
import axios from 'axios';

function Navbar() {
  const history = useHistory();
  const [click, setClick] = useState(false);
  const [cookies] = useCookies();
  const closeMobileMenu = () => setClick(false);
  const [currentVersion,setCurrentVersion] = useState("");

  // When refresh then reload the page.
  const refresh = ()=>{
      window.location.reload(false);
  }

  // On Log out release the fax lock.
  const LogOutFunction = ()=>{
      axios.get(`${process.env.REACT_APP_BaseUrl}releaselocks`, 
        { headers: { "firstName":cookies.firstName,"lastName":cookies.lastName,"email":cookies.email  }}
      ).then(res => {
        window?.location?.replace(`${window?.location?.origin}`);   
      }).catch(err => {
          console.log(err,"releaselocks");
      })
  }
  const getSystemVersion = ()=>{
    if(currentVersion == ""){
      axios.get(`${process.env.REACT_APP_BaseUrl}getsystemversion`, 
        { headers: { "firstName":cookies.firstName,"lastName":cookies.lastName,"email":cookies.email  }}
      ).then(res => {
        setCurrentVersion(res.data);
      }).catch(err => {
          console.log(err,"getsystemversion");
      })
    }
  }
  return (
    <>
      <div className='newNavbar'>
        <Row>
          <Col sm={2} className="mt-2 mb-2">
            {
              history?.location?.pathname == "/home" ?
              <Link to={{pathname: "/home", state: {indexPageState:{alias:[],searchCallerId:"",pageNumber:1}}}} className='navbar-logo'>
              <img src='./logo.png' className='logo' onClick={refresh}></img>
              </Link>
            :
            <Link to={{pathname: "/home", state: {indexPageState:{alias:[],searchCallerId:"",pageNumber:1}}}} className='navbar-logo' onClick={closeMobileMenu}>
            <img src='./logo.png' className='logo'></img>
            </Link>
            }
          </Col>
          <Col sm={8}>
            <ul className="myNavMenu">
              <li className='nav-item'>
                    {
                      history?.location?.pathname == "/home"  || history?.location?.pathname == "/processview" || 
                      history?.location?.pathname == "/viewpage" ?
                      <div className='navmenu-new'>
                        {
                          history?.location?.pathname == "/home" ? 
                          <Link to={{pathname: "/home", state: {indexPageState:{alias:[],searchCallerId:"",pageNumber:1} }}}><span className='underline active' onClick={refresh}>INBOX</span></Link>
                          :<Link to={{pathname: "/home", state: {indexPageState:{alias:[],searchCallerId:"",pageNumber:1}}}}><span className='underline active' >INBOX</span></Link>
                        } 
                        
                      </div>
                      :
                      <div className='navmenu-new'> 
                        {
                          history?.location?.pathname == "/home" ? 
                          <span onClick={refresh}>INBOX</span>
                          :<Link to={{pathname: "/home", state: {indexPageState:{alias:[],searchCallerId:"",pageNumber:1} }}}><span>INBOX</span></Link>
                        }
                      </div>
                    }
              </li >

              <li className='nav-item'>
                  <div className='navmenu-new disable'>
                    <Link to='/U-Construction'>USER ACCESS</Link>
                  </div>
              </li>
            </ul>
          </Col>
          <Col sm={2} className="userpro">
            <Col sm={12} className="text-right userproleft">
              <h6 style={{padding: "0px 15px"}}>Welcome</h6>
              <Dropdown onClick={()=>getSystemVersion()}>
              <Dropdown.Toggle  className='username'>{cookies.firstName} {cookies.lastName}</Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item>Version - {currentVersion}</Dropdown.Item>
              <Dropdown.Item onClick={()=>LogOutFunction()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Navbar;
