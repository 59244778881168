import React from 'react';
import Navbar from '../components/Navbar'
import UnderConstruction from '../CommonComponents/UnderConstruction';

function Underconstuction(props) {
    return (
        <div>
            <Navbar />
            <UnderConstruction/>
        </div>
    );
}

export default Underconstuction;