import React, { useEffect, useState,useMemo } from 'react';
import packageJson from '../../package.json';
import {Col, Row} from "react-bootstrap";
import { Viewer,Worker,RotateDirection,SpecialZoomLevel,ScrollMode,PrimaryButton } from '@react-pdf-viewer/core';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { SelectionMode,selectionModePlugin  }  from '@react-pdf-viewer/selection-mode';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { GrRotateRight} from "react-icons/gr";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css'


const PdfThumnailView = (props) =>{
    const thumbnailPluginInstance = thumbnailPlugin({
        thumbnailWidth: 150,
    });
    const { Thumbnails } = thumbnailPluginInstance;    
    const rotatePluginInstance = rotatePlugin();
    const { RotatePage,Rotate } = rotatePluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const selectionModePluginInstance = selectionModePlugin();
    const { SwitchSelectionModeButton } = selectionModePluginInstance;
    const defaultLayoutPluginInstance = ({
            fullScreenPlugin: {
                onEnterFullScreen: (zoom) => {
                    zoom(SpecialZoomLevel.PageFit);
                    defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                        ScrollMode.Wrapped
                    );
                },
                onExitFullScreen: (zoom) => {
                    zoom(SpecialZoomLevel.PageWidth);
                    defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                        ScrollMode.Vertical
                    );
                },
            },
    });
    const [pdfFile,setPdfFile] = useState();
    const [pagesArray,setPagesArray] = useState([])
    const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
    const [pageOriginalNum,setpageOriginalNum] = useState([]);
    const [activePage,setactivePage] = useState([]);
    const [selcttionMode, setSelectionMode] = useState("Text");

    // Set the initial values to existing parameter and pdf url.
    useEffect(()=>{
        if(props?.pagesArray){
            setPagesArray([...props?.pagesArray]);
        }
        if(props?.pdf) {
            let url  = URL.createObjectURL(props?.pdf)
            setPdfFile(url);
        }
        if(props?.pageOriginalNum){
            setpageOriginalNum([...props?.pageOriginalNum])
        }
    },[props])

    // For pdf thumbnail shown.
    const renderThumbnailItem = (props) => {
        setactivePage(props?.currentPage);
        return (
        <div key={props?.key} className={`${pagesArray?.indexOf(props?.pageIndex+1) === -1 ? "":"disable-pdf-thumbnail"} `} style={{position:"relative", marginBottom:"10px"}}>
            {/* Jump to the corresponding page when clicking the thumbnail */}
            <div onClick={props?.onJumpToPage} className={`${props?.pageIndex==props?.currentPage? "abc ":""}`}>
                {/* Thumbnail image */}
                {props?.renderPageThumbnail}
            </div>
    
            {/* Page label */}
            <div className={`${props?.pageIndex==props?.currentPage? "pdf-pagenum-active":"pdf-pagenum-inactive"}`}>
            {props?.renderPageLabel}
            </div>
              {  
                 pageOriginalNum && pageOriginalNum.length>0 ?
                <div>
                    <p>Splitted Page-{pageOriginalNum[props?.pageIndex]}</p>
                </div>
                  :""
              }
        </div>
        )
    }

    // Selecting the hand or arrow on pdf.
    const loadpage =(selectedMode)=>{
        setSelectionMode(selectedMode);
    }
    const renderFun = (e)=>{
        console.log(e,"ddddddd")
    }
    const cachedValueddd = useMemo(() => { return  (<>
    <div className="rpv-core__viewer"  
        style={{
            // border: '1px solid #d6d6d6',
            // display: 'flex',
            height: '100%',
            // borderRadius:'20px',
            // overflow:'auto'
        }}>
            <Row style={{height:"5%"}}>
                <div style={{
                        alignItems: 'center',
                        backgroundColor: '#eeeeee',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4px',
                    }}>
                    <ZoomInButton />
                    <ZoomPopover />
                    <ZoomOutButton />
                    {console.log(activePage,"current")}
                    <RotatePage>
                        {(props) =>{ 
                            return (
                            <GrRotateRight onClick={() => {
                                props.onRotatePage(activePage, RotateDirection.Forward);
                                renderThumbnailItem(props);
                                }}>
                            </GrRotateRight>
                        )}}
                    </RotatePage>
                    {
                        <span onClick={()=>loadpage("Text")}>
                            <SwitchSelectionModeButton  mode={SelectionMode.Text}  >
                            </SwitchSelectionModeButton>
                        </span>
                    }
                    {
                        <span onClick={()=>loadpage("Hand")}>
                            <SwitchSelectionModeButton  mode={SelectionMode.Hand}  >
                            </SwitchSelectionModeButton>
                        </span>
                    }
                </div>
            </Row> 
            <Row style={{display: 'flex',height:'95%'}}>
                <Col sm={3} style={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                        // overflow: 'scroll',
                        width: '20%',
                        height:"100%"
                    }} >                    
                    <Thumbnails renderThumbnailItem={renderThumbnailItem} />                           
                </Col>
                
                <Col sm={9} style={{width:'80%'}}>
                    <Viewer  fileUrl={pdfFile} plugins={[thumbnailPluginInstance,rotatePluginInstance,zoomPluginInstance,selectionModePluginInstance,defaultLayoutPluginInstance]} defaultScale={SpecialZoomLevel.PageWidth} />
                </Col>
            </Row>
        </div>
    </>)
    }, [SpecialZoomLevel.PageWidth,thumbnailPluginInstance,rotatePluginInstance,zoomPluginInstance,selectionModePluginInstance,defaultLayoutPluginInstance,pdfFile]);
    return(
        <> 
        {/* This is worker file need for pdf show, this is third party library with @react-pdf-viewer */}
        <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}></Worker>
        {
            pdfFile ? 
            <div className="rpv-core__viewer"  
                style={{
                    height: '100%',
                }}>
                <Row style={{height:"5%"}}>
                    <div style={{
                        alignItems: 'center',
                        backgroundColor: '#eeeeee',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4px',
                    }}>
                    <ZoomInButton />
                    <ZoomPopover />
                    <ZoomOutButton />
                    {console.log(activePage,"current")}
                    <RotatePage>
                        {(props) =>{ 
                            return (
                            <GrRotateRight onClick={() => {
                                props.onRotatePage(activePage, RotateDirection.Forward);
                                renderThumbnailItem(props);
                                }}>
                            </GrRotateRight>
                        )}}
                    </RotatePage>
                    {
                        <span onClick={()=>loadpage("Text")}>
                            <SwitchSelectionModeButton  mode={SelectionMode.Text}  >
                            </SwitchSelectionModeButton>
                        </span>
                    }
                    {
                        <span onClick={()=>loadpage("Hand")}>
                            <SwitchSelectionModeButton  mode={SelectionMode.Hand}  >
                            </SwitchSelectionModeButton>
                        </span>
                    }
                    </div>
                </Row> 
                <Row style={{height:'95%'}}>
                    <Col sm={2.5} style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                            // overflow: 'scroll',
                            width: '20%',
                            height:"100%"
                        }} >                    
                        <Thumbnails renderThumbnailItem={renderThumbnailItem} />                           
                    </Col>
                    
                    <Col sm={9.5} style={{width:'80%'}}>
                        <div style={{width:'100%'}}>
                            <Viewer onDocumentLoad={renderFun} fileUrl={pdfFile} plugins={[thumbnailPluginInstance,rotatePluginInstance,zoomPluginInstance,selectionModePluginInstance,defaultLayoutPluginInstance]} defaultScale={SpecialZoomLevel.PageFit} />
                        </div>
                    </Col>
                </Row>
            </div>
            :""
        }
        </>
    )

}

export default  PdfThumnailView;