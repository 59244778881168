import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import packageJson from '../package.json';
import { Viewer,Worker,RotateDirection,SpecialZoomLevel,ScrollMode,PrimaryButton } from '@react-pdf-viewer/core';

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"]
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <CookiesProvider>
    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}></Worker>
      <App />
    </CookiesProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
