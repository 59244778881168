import React from 'react';
import Navbar from '../components/Navbar'

function UserAccess(props) {
    return (
        <div>
            <Navbar />
        </div>
    );
}

export default UserAccess;