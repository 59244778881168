import { useState } from "react";

function CommanSpinner(props) {
  const [spinner,setspinner] = useState(props?.spinner ? props?.spinner :"");
    return(
        spinner == "" ?
        ""
        :
        <div className="addbtnpopup">
           <div className="popupbackdrop">
              <button className="btn loading-btn" type="button" disabled>
                <span className="spinner-border spinner-border-sm loading-icon text-light"  role="status" aria-hidden="true"></span>
                {props?.text ? <p className="loading-txt">{props?.text}</p>  :""}
              </button>
           </div>
       </div>
    );
}
export default CommanSpinner;