import React from 'react';
import { useCookies } from "react-cookie";


const Login = () => {
    const [cookies,setCookies,removeCookies] = useCookies();
    //Hit the Url for login 
    const loginwithAzureAd = ()=>{
        if(window?.location?.href){
            // console.log(window?.location?.href,"")
            if(!cookies.firstName){
                let url = `${process.env.REACT_APP_BaseUrl?.split("/api")[0]}`
                window?.location?.replace(url);
            }else{
                let url = `${window?.location?.origin}/home`
                window?.location?.replace(url);
            }
        }
    }
    return (
        <div className='wrapper-p'>
        <div className="wrapper">
            <div className="logo mb-4">
                <img src="./logo.png" alt=""/>
            </div>
            <div className="text-center name">
            Sign back in to Bedard Medical Supplies
                <div><a href="#">Bedard.app</a></div>
            </div>
            <div className="text-center name1">
            Please log in through Bedard Medical Supplies' single sign-on solution.
            </div>
            <div className='text-center mt-3'>
                <button className='form-btn' onClick={()=>loginwithAzureAd()}>
                Sign in with Azure Active Directory
                </button>
            </div>
            <div className="text-center name2">
            To access this site, you agree to Bedard Medical Supplies' Acceptable Use Agreement and Security Policies.
            </div>

           {/* <form className="p-3 mt-3">
                <div className="form-field d-flex align-items-center">
                    <input type="text" name="username"  placeholder="Username*" onChange={ChangeValue} required/>
                </div>
                <div className="form-field d-flex align-items-center">
                    <input type="password" name="password"  placeholder="Password*" onChange={ChangeValue} required />
                </div>
                <button className="btn mt-3" onClick={(e)=>{UserLogin(e)}} >LOGIN</button>
            </form> */}
        </div>
        </div>
    );
};

export default Login;